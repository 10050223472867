var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "picWrapper" },
    [
      _c("pic-con", {
        ref: "pic",
        attrs: {
          pics: _vm.pics,
          curPos: _vm.curPos,
          prePicVisible: _vm.picV,
          showDesc: _vm.showDesc || "uploadTime",
          direction: _vm.direction,
          imgStyle: _vm.imgStyle,
        },
        on: {
          "update:prePicVisible": function ($event) {
            _vm.picV = $event
          },
          "update:pre-pic-visible": function ($event) {
            _vm.picV = $event
          },
          leftClick: _vm.handleLeftClick,
          rightClick: _vm.handleRightClick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }